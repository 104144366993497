<template>
  <v-dialog v-model="toogleEditModal"
            max-width="500">
    <v-card>
      <v-card-title>
        {{$vuetify.lang.t('$vuetify.titles.edit', [$vuetify.lang.t('$vuetify.menu.account_bill')])}}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editBill.name"
                :rules="formRule.required"
                :label="$vuetify.lang.t('$vuetify.firstName')"
              />
            </v-col>
            <v-col col="12" md="12">
              <v-textarea
                v-model="editBill.description"
                :label="$vuetify.lang.t('$vuetify.description')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="toogleEditModal(false)">
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="saveBill">
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NewBills',
  data () {
    return {
      formRule: this.$rules,
      formValid: false
    }
  },
  computed: {
    ...mapState('bill', ['editBill'])
  },
  methods: {
    ...mapActions('bill', ['updateBill', 'toogleEditModal']),
    saveBill () {
      if (this.$refs.form.validate()) { this.updateBill(this.editBill) }
    }
  }
}
</script>

<style scoped>

</style>
