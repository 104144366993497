<template>
  <v-container>
    <new-bills v-if="showNewModal" @reloadData="reloadData"/>
    <edit-bills v-if="showEditModal"/>
    <bill-value :bill_id="billId" v-if="showShowModal" @reloadData="reloadData"/>
    <app-data-table
      :items="bills"
      :manager="'accounting_account'"
      :headers="getColumnHeader"
      :showBillButton="true"
      @create-row="toogleNewModal(true)"
      @edit-row="editMoveHandler($event)"
      @bill-row="billHandler($event)"
      @reload-data="reloadData($event)"
      :viewShowFilterDate="true"
      :options="vBindOption"
    >

      <template
        v-slot:[`item.name`]="{ item }"
      >
        {{item.name}} ({{parseFloat(item.cant)}} {{getCurrency}})
      </template>
      <template
        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
      >
        <v-btn
          v-if="item.bill_sales.length > 0"
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <v-app-bar
            v-if="item.bill_sales.length > 0"
            flat
            dense
            color="rgba(0, 0, 0, 0)"
          >
            <h4>{{ $vuetify.lang.t('$vuetify.titles.extra_sale_bills') }}</h4>
          </v-app-bar>
          <v-simple-table dense v-if="item.bill_sales.length > 0">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ $vuetify.lang.t('$vuetify.tax.value') }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t('$vuetify.date') }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t('$vuetify.generated_by_sale') }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(bill, k) in item.bill_filters" :key="k">
                <td>{{bill.cant}}</td>
                <td>{{bill.date_bill}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </app-data-table>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import NewBills from './NewBills'
import EditBills from './EditBills'
import BillValue from './BillValue'

export default {
  name: 'ListBills',
  components: { BillValue, EditBills, NewBills },
  data () {
    return {
      filter: null,
      billId: null,
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  computed: {
    ...mapState('bill', ['bills', 'showNewModal', 'showEditModal', 'showShowModal']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getColumnHeader () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.first_name'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  /* async created () {
    this.loading = true
    await this.getBills()
    this.loading = false
  }, */
  methods: {
    ...mapActions('bill', ['getBills', 'toogleNewModal', 'openEditModal', 'openShowModal', 'getBillsFilter']),
    editMoveHandler ($id) {
      this.openEditModal($id)
    },
    billHandler ($id) {
      this.billId = $id
      this.openShowModal($id)
    },
    async reloadData (filter) {
      if (filter) { this.filter = filter }
      await this.getBillsFilter(this.filter)
    }
  }
}
</script>

<style scoped>

</style>
